@tailwind base;
@tailwind components;
@tailwind utilities;

.text-input {
    width: 750px;
    height: 50px;
}

.text-input[type="date"] {
    width: 750px;
}

@media (max-width: 768px) {
    .text-input {
        width: 100%;
    }

    .text-input[type="date"] {
        width: 100%;
    }
}

/* for tablets make it less wide */
@media (max-width: 1024px) and (min-width: 768px) {
    .text-input {
        width: 500px;
    }

    .text-input[type="date"] {
        width: 500px;
    }
}

/* For the floating effect */
.warning-alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* To ensure it's above other content */
}

/* Slide down transition effect */
.slide-down {
    transform: translateY(-100%);
    animation: slideDown 0.5s forwards;
}

@keyframes slideDown {
    to {
        transform: translateY(0);
    }
}

.filter-invert {
    filter: invert(1);
}

@media (min-width: 1024px) {
    .lg\:w-4\/5 {
        width: 80%;
    }
}
